export const loadImage = async (imageSrc: string): Promise<HTMLImageElement> => {
    return new Promise<HTMLImageElement>((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = "anonymous";

        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => {
            console.log(error);

            reject(new Error("error to load image"));
        })
        image.src = imageSrc;
    });
}