import { useRef, useState, useEffect } from "react";
import loadSVG from "../../utils/loadSVG"
import "../../style/components/canvas/index.css"
import _config from "../../config"
import { toast } from "sonner";
import { Errors } from "../../utils/errors";
const Canvas = (props: {
    CanvasEl: JSX.Element,
    use_image: boolean,
    imageID: number
    setSVG: (svg: SVGSVGElement) => void
    // setMessage: React.Dispatch<React.SetStateAction<{
    //     color: string;
    //     content: string;
    // } | null>>,
    config: Config

}) => {
    const { CanvasEl, use_image, imageID, setSVG, config } = props;
    const [svgString, setSvgString] = useState<string | null>(null)
    const [loading, setLoading] = useState(true);
    const refSVGContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const load = async () => {
            try {
                if (!config.use_multi_image) return;
                if (!config.use_image) return;
                if (svgString) return;
                const loadSvg = await loadSVG(`${_config.api_server_uri}/svg/${config.image_paths[imageID].id}`);
                if (use_image && loadSvg) {
                    const DOMparser = new DOMParser().parseFromString(loadSvg, "image/svg+xml");
                    const svg = DOMparser.querySelector('svg');
                    if (svg) {
                        setSvgString(svg.outerHTML)
                    } else toast.error("error to find svg");
                } else {
                    if (use_image) toast.error("error to load image");
                }
            } catch (error) {
                Errors.handler(error);
            } finally {
                setLoading(false);
            }
        }
        load();
        if (refSVGContainer.current) {
            const svg = refSVGContainer.current.querySelector('svg');
            if (svg) {
                setSVG(svg);
            }
        }
    }, [config.image_paths, imageID, setSVG, use_image]);

    return (<>
        {
            !loading ?
                <div className="canvas-container">
                    {svgString ? <div className="svg-container" dangerouslySetInnerHTML={{ __html: svgString }} ref={refSVGContainer} style={{ width: config.canvas.default_size.width, height: config.canvas.default_size.height }}></div> : null}
                    {
                        CanvasEl
                    }
                </div>
                :
                null
        }
    </>
    );
};

export default Canvas;