import { LoaderFunction, redirect, useLoaderData, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import ColorPallete from "../../components/colors-pallete";
import configG from "../../config";
import Message from "../../components/message";
import Pen from "../../pen";
import Canvas from "../../components/canvas";
import BottomBar from "../../components/bottom-bar";
import isLogin from "../../utils/isLogin";
import axios from "axios";
import Claer from "../../components/widgets/clear";
import PostImage from "../../utils/post/image";
import MessageColor from "../../config/colors";
import Finish from "../../components/finish";
import LiveDrawForm from "../../components/live_draw_form";
import { LoadFont } from "../../utils/loadFont";
import GetName from "../../components/get-name";
import _config from "../../config";


// new branch sync
const Paint = () => {
    const paint = useLoaderData() as { name: string; config: Config; id: string };

    const config = paint.config;
    const [colorIndex, setColorIndex] = useState(config.default_colors_select);
    const [toolIndex, setToolsIndex] = useState(config.default_tools_select);
    const [message, setMessage] = useState<{ color: string; content: string } | null>(null);
    const [widget, setWidget] = useState<JSX.Element | null>(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [finish, setFinish] = useState<{ image_url: string; labelName: string } | null>(null);
    const penRef = useRef<Pen>(new Pen(config.colors_palette[colorIndex], 8, config.use_image, config));
    const pen = penRef.current;
    const [liveDrawIsConnnect, setLiveDrawIsConnnect] = useState(false);
    const [liveDrawIsLoading, setLiveDrawIsLoading] = useState(config.live_draw.state);
    const [users, setUsers] = useState<{ x: number; y: number; color: string; username: string; iconPath: string }[]>([]);
    const [getName, setGetName] = useState<boolean>(config.get.name.use);
    // let galleryName = config.default_gallery_name;
    const { imageID } = useParams();
    const [imageId] = useState<string | undefined>(imageID);

    pen.setMessage = setMessage;
    pen.setLiveDrawIsConnnect = setLiveDrawIsConnnect;
    pen.setUsers = setUsers;
    const onColorClick: onPalleteClick = (index, type) => {
        if (type === "color") {
            pen.setColor(config.colors_palette[index]);
            setColorIndex(index);
        } else {
            pen.undo();
        }
    };
    const setSVG = (svg: SVGSVGElement) => {
        pen.setSVG(svg);
    };
    const setCanvas = (canvas: HTMLCanvasElement) => {
        if (pen.canvas) return;

        pen.setCanvas(canvas);
        pen.setup();

        const currentTool = config.tools.find((tool) => (tool.action === "bucket" || tool.action === "eraser" || tool.action === "marker" ? tool.active : false)) || config.tools[toolIndex];

        if (currentTool.action === "marker" || currentTool.action === "bucket" || currentTool.action === "eraser") {
            if (currentTool.action === "marker") {
                pen.setBrush({
                    cursor_style: currentTool.cursor_style,
                    size: currentTool.size,
                    type: "marker",
                    iconPath: currentTool.iconPath,
                });
            } else if (currentTool.action === "eraser") {
                pen.setBrush({
                    cursor_style: currentTool.cursor_style,
                    size: config.eraser.size,
                    type: "eraser",
                    iconPath: currentTool.iconPath,
                });
            } else {
                pen.setBrush({
                    cursor_style: currentTool.cursor_style,
                    size: NaN,
                    type: "bucket",
                    iconPath: currentTool.iconPath,
                });
            }
        }
    };
    const onToolClick: OnToolClick = (tool, index) => {
        switch (tool.action) {
            case "marker":
                pen.setBrush({
                    type: "marker",
                    size: tool.size,
                    cursor_style: tool.cursor_style,
                    iconPath: tool.iconPath,
                });
                setToolsIndex(index);
                break;
            case "bucket":
                pen.setBrush({
                    cursor_style: tool.cursor_style,
                    type: "bucket",
                    size: NaN,
                    iconPath: tool.iconPath,
                });
                setToolsIndex(index);
                break;
            case "eraser":
                pen.setBrush({
                    cursor_style: tool.cursor_style,
                    type: "eraser",
                    iconPath: tool.iconPath,
                    size: NaN,
                });
                setToolsIndex(index);
                break;
            case "link":
                if (tool._blank) {
                    window.open(tool.href);
                } else {
                    window.location.href = tool.href;
                }
                break;
            case "addToGallery":
                if (!pen.canPost) {
                    setMessage({ color: MessageColor.error, content: "you need to draw some" });
                    return;
                }
                // if (config.live_draw.state) {
                //     if (!pen.isChef) return setMessage({ color: MessageColor.error, content: "you are not the manager" });
                // }
                let galleryLable = "default";
                if (config.questions) {
                    const question = config.questions[currentQuestionIndex];
                    galleryLable = question.label;
                }

                const imageData = pen.render();

                if (imageData) {
                    let name = null;
                    if (config.get.name.use) {
                        name = localStorage.getItem(`${paint.id}-name`);
                        if (!name) {
                            setMessage({
                                color: MessageColor.error,
                                content: "error to find name",
                            });

                            return;
                        }
                    }
                    PostImage(configG.api_server_uri, paint.id, galleryLable, imageData, name).then((res) => {
                        if (res.ImageUrl && typeof res.label === "string") {
                            pen.canPost = false;
                            setFinish({
                                labelName: res.label,
                                image_url: `${configG.api_server_uri}/${res.ImageUrl}`,
                            });
                        } else {
                            setMessage({
                                color: MessageColor.error,
                                content: res.message,
                            });
                        }
                    });
                } else {
                    setMessage({ color: MessageColor.error, content: "error to render image" });
                }
                break;
            case "clear":
                setWidget(<Claer pen={pen} setWiget={setWidget} html_interface={tool.html_interface} />);
                break;
        }
    };
    const refCanvas = useRef<JSX.Element>(<canvas ref={(canvas) => (canvas ? setCanvas(canvas) : false)}></canvas>);
    const handleLiveDrawForm = (token: string) => {
        localStorage.setItem(paint.id, token);
        pen.setSocket(token, paint.id);
    };
    useEffect(() => {
        document.title = config.title.home.replace(/{{name}}/, paint.name);
        if (config.questions) {
            const param = new URLSearchParams(window.location.search);
            const questionIndexString = param.get("qi");
            const questionIndex = Number(questionIndexString);
            setCurrentQuestionIndex(questionIndex);
        }
        if (config.live_draw.state) {
            const token = localStorage.getItem(paint.id);
            if (token) {
                pen.setSocket(token, paint.id);
            } else {
                setLiveDrawIsLoading(false);
            }
        }
        try {
            LoadFont(config.font);
        } catch (err) {
            if (err instanceof Error) {
                setMessage({ color: MessageColor.error, content: err.message });
            }
        }
        return () => {
            pen.destroy();
        };
    }, [config.live_draw.state, config.questions, config.title.home, paint.id, paint.name, pen, config.font]);

    useEffect(() => {
        if (config.get.name.use) {
            const localName = localStorage.getItem(`${paint.id}-name`);

            if (localName) {
                setGetName(false);
            }
        }
    }, [config.get.name.use, paint.id]);
    if (config.use_multi_image && !imageId) {
        return (
            <div className="w-full h-screen overflow-hidden relative flex items-center justify-start flex-col" style={{ zIndex: 50000 }}>
                <div className="w-full h-1/5 flex items-center justify-center ">
                    <h1 className="text-2xl font-bold">choose image</h1>
                </div>
                <div className=" bg-white grid grid-cols-2 gap-4 py-16 overflow-y-scroll md:grid-cols-3">
                    {config.image_paths.map((path, index) => {
                        let currentUrl = window.location.pathname;

                        if (!currentUrl.endsWith("/")) currentUrl += "/";
                        let newUrl = currentUrl + `${index}`;
                        return (
                            <a href={newUrl} key={index}>
                                <img src={`${_config.api_server_uri}/svg/${path.id}`} alt="" />
                            </a>
                        );
                    })}
                </div>
            </div>
        );
    }
    return (
        <div className="w-screen h-screen overflow-hidden relative">
            {config.live_draw.state && !liveDrawIsConnnect ? <LiveDrawForm handleLiveDrawForm={handleLiveDrawForm} liveDrawIsLoading={liveDrawIsLoading} paintId={paint.id} /> : null}
            {getName ? (
                <GetName
                    handle={(name) => {
                        localStorage.setItem(`${paint.id}-name`, name);
                        setGetName(false);
                    }}
                    max={config.get.name.max}
                    min={config.get.name.min}
                />
            ) : null}
            {finish ? (
                <Finish
                    config={config}
                    image_url={finish.image_url}
                    close={() => setFinish(null)}
                    paintId={paint.id}
                    next_question_index={config.questions ? (config.questions.length > currentQuestionIndex + 1 ? currentQuestionIndex + 1 : undefined) : undefined}
                    label={finish.labelName}
                    paint={paint.name}
                />
            ) : null}

            {widget ? widget : null}
            {message ? <Message content={message.content} setMessage={setMessage} color={message.color} /> : null}
            {users.map(({ iconPath, username, x, y }, index) => {
                return (
                    <div key={index} className="absolute pointer-events-none" style={{ top: `${y}px`, left: `${x}px`, zIndex: 100 }}>
                        <img src={iconPath} alt="user" />
                        <span>{username}</span>
                    </div>
                );
            })}
            <ColorPallete onClick={onColorClick} colorIndex={colorIndex} config={paint.config} />
            {config.questions ? <div className="text-center font-bold">{config.questions[currentQuestionIndex].value}</div> : null}
            <Canvas
                CanvasEl={refCanvas.current}
                use_image={config.use_image}
                imageID={config.use_multi_image && !isNaN(Number(imageID)) ? Number(imageID) : config.default_image_select}
                setSVG={setSVG}
                config={config}
            />
            <BottomBar colorSelect={config.colors_palette[colorIndex]} use_image={config.use_image} onToolClick={onToolClick} toolIndex={toolIndex} tools={config.tools} />
        </div>
    );
};
export default Paint;
export const loader: LoaderFunction = async ({ params, request }) => {
    await isLogin();
    try {
        const res = await axios.post(`${configG.api_server_uri}/paint/${params.id}/config`);
        if (res.status === 200) {
            const config: Config = res.data.config;
            const clientUrl = new URL(request.url);
            const param = new URLSearchParams(clientUrl.search);

            const questionIndexString = param.get("qi");
            const questionIndex = Number(questionIndexString);
            if (!config.questions) return res.data;
            if (config.questions && questionIndexString && !isNaN(questionIndex) && config.questions[questionIndex]) {
                return res.data;
            } else return redirect("./?qi=0");
        } else {
            return redirect("/paint/");
        }
    } catch (err) {
        return redirect("/paint/");
    }
};
