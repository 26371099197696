import { useState, useEffect, useRef } from "react"
import TComposeImage from "../../@types/composeImage";
import UseDrapAndDrop from "../../drapXdrop";
import { LoaderFunction, redirect, useLoaderData, useParams } from "react-router-dom";
import config from "../../config";
import axios, { AxiosError } from "axios";
import isLogin from "../../utils/isLogin";
import Message from "../../components/message";
import MessageColor from "../../config/colors";
import FinishCompose from "../../components/finish-compose";
import { toast } from "sonner";
import { loadImage } from "../../utils/image";
const configCompose = {
    compose: {
        // svg: {
        //     width: 500,
        //     height: 500
        // },
        image: {
            width: 100,
            height: 100
        }
    }
}
interface TGallery {
    paint: {
        name: string;
        config: Config;
    };
    storages: {
        label: string;
        images: {
            filepath: string;
        }[];
        max_images: number;
        _id: string;
    }[]
}
const Compose = () => {
    const data = useLoaderData() as {
        gallery: TGallery,
        user: User,
    };
    const configPaint = data.gallery.paint.config
    const [size, setSize] = useState(configPaint.interatif.default_size);

    const param = useParams();
    const paintId = param.id!;
    const imageContainerRef = useRef<null | HTMLDivElement>(null);
    const [message, setMessage] = useState<{ color: string, content: string } | null>(null)
    const [finish, setFinish] = useState<{ image_url: string } | null>(null)
    const selectImage = useRef<{ firstTouch: Position, index: number, mousedown: boolean, element: SVGImageElement | null, size: Size | null } | null>(null);
    const reScale = useRef<{ postion: Position, mousedown: boolean } | null>(null)
    const setRender = useState({})[1]
    const [images, setImage] = useState<string[]>([])
    const [composeImages, setComposeImage] = useState<TComposeImage[]>([]);
    const [layoutImage, setLayoutImage] = useState<{
        svgText: string,
        blob: Blob,
    } | null>(null)

    const svgRef = useRef<SVGSVGElement | null>(null);
    const resize = () => {
        setRender({});
        if (window.innerWidth <= configPaint.interatif.default_size.width + 200) {
            const newWidth = window.innerWidth * 0.7;
            const scale = newWidth / configPaint.interatif.default_size.width;
            const newHeight = configPaint.interatif.default_size.height * scale;

            setSize({ width: window.innerWidth * 0.7, height: newHeight })
        } else {
            setSize(configPaint.interatif.default_size)
        }
    };
    const drop = (element: HTMLImageElement, position: { x: number, y: number }, target: Element) => {
        // to remake
        const svg = svgRef.current;
        if (!svg) return;
        // if (svg !== target) return;
        const src = element.src;
        const scalePositon = {
            x: position.x * configPaint.interatif.default_size.width / svg.width.animVal.value,
            y: position.y * configPaint.interatif.default_size.height / svg.height.animVal.value
        }
        setComposeImage((composeImages) => {
            selectImage.current = { firstTouch: { x: 0, y: 0 }, index: composeImages.length, mousedown: false, element: null, size: null };

            return [...composeImages, {
                position: scalePositon,
                size: { ...configCompose.compose.image },
                src
            }];
        })

    }
    const { drapElement, setListener } = UseDrapAndDrop(drop, configCompose.compose.image);
    const reloadImage = async () => {
        try {
            const imagesData: string[] = [];

            const responce = await axios.post(`${config.api_server_uri}/paint/gallery/${paintId}`);
            const data: TGallery = responce.data;
            data.storages.forEach((storage) => {
                storage.images.forEach((image) => {
                    imagesData.push(`${config.api_server_uri}${image.filepath}`)
                })
            })
            setImage(imagesData)

        } catch (err) {
            alert("error to reload")
        }
    };
    const getImage = () => {
        const imagesData: string[] = [];
        data.gallery.storages.forEach((storage) => {
            storage.images.forEach((image) => {
                imagesData.push(`${config.api_server_uri}${image.filepath}`)
            })
        })

        setImage(imagesData)
    }
    const mousemove = (event: MouseEvent | TouchEvent) => {

        if (svgRef.current) {
            let offsetX: number;
            let offsetY: number;
            let clientX: number;
            let clientY: number;
            const svgCoord = svgRef.current.getBoundingClientRect();
            if (event instanceof TouchEvent) {
                clientX = event.targetTouches[0].clientX;
                clientY = event.targetTouches[0].clientY;
                offsetX = clientX - svgCoord.x
                offsetY = clientY - svgCoord.y

            } else {
                clientX = event.clientX;
                clientY = event.clientY;
                offsetX = clientX - svgCoord.x
                offsetY = clientY - svgCoord.y
            }


            if (selectImage.current && selectImage.current.mousedown) {
                const svg = svgRef.current;

                const position = {
                    x: ((offsetX - selectImage.current.firstTouch.x) * configPaint.interatif.default_size.width) / svg.width.animVal.value,
                    y: ((offsetY - selectImage.current.firstTouch.y) * configPaint.interatif.default_size.height) / svg.height.animVal.value
                };
                const index = selectImage.current.index;

                setComposeImage((composeImage) => {
                    composeImage[index].position = position;
                    return composeImage;
                })
            }

            if (reScale.current && reScale.current.mousedown && selectImage.current && selectImage.current.size) {
                let scaleX = (clientX - reScale.current.postion.x);
                let scaleY = (scaleX / configCompose.compose.image.width) * configCompose.compose.image.height;
                const index = selectImage.current.index;
                const imageSize = {
                    width: selectImage.current.size.width - (-scaleX),
                    height: selectImage.current.size.height - (-scaleY)
                };
                if (imageSize.width < 25) return;

                setComposeImage((composeImages) => {
                    // composeImages[index].size.width = configCompose.compose.image.width - (-scaleX)
                    // composeImages[index].size.height = configCompose.compose.image.height - (-scaleY);
                    composeImages[index].size = imageSize;
                    return composeImages;
                })


            }
        };
    }
    const mouseup = (event: MouseEvent | TouchEvent) => {
        if (selectImage.current) {
            selectImage.current.mousedown = false;
            selectImage.current.size = null;

        }
        if (reScale.current) reScale.current.mousedown = false;
        setRender({});
    }
    const removeImage = () => {
        if (!selectImage.current) return;

        const index = selectImage.current.index
        setComposeImage((composeImages) => {
            const newComposeImags = [...composeImages];
            newComposeImags.splice(index, 1);

            return newComposeImags
        });
        selectImage.current = null;
    }
    const render = async (): Promise<string> => {
        const canvas = document.createElement('canvas');
        const svg = svgRef.current
        const ctx = canvas.getContext('2d');

        canvas.width = configPaint.interatif.default_size.width;
        canvas.height = configPaint.interatif.default_size.height;


        if (ctx && svg) {
            ctx.fillStyle = "#FFF"
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            for (let i = 0; i < composeImages.length; i++) {
                const composeImage = composeImages[i];

                const image: HTMLImageElement = await loadImage(composeImage.src);
                ctx.drawImage(image, composeImage.position.x, composeImage.position.y, composeImage.size.width, composeImage.size.height)
            }

            if (layoutImage) {
                console.log(layoutImage);
                const url = URL.createObjectURL(layoutImage.blob);
                console.log(url);
                const image = await loadImage(url);
                ctx.drawImage(image, 0, 0, canvas.width, canvas.width)
            }
            return canvas.toDataURL();

        } else {
            throw new Error('error to get context')
        }
    }
    const postImage = async () => {
        try {
            const imageData = await render();
            const responce = await axios.post(`${config.api_server_uri}/dashboard/paint/compose/${paintId}`, { image: imageData }, { headers: { authorization: data.user.token } });
            if (responce.status === 200) {
                setFinish({
                    image_url: `${config.api_server_uri}${responce.data.ImageUrl}`
                })
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.response) {
                    setMessage({
                        color: MessageColor.error,
                        content: error.response.data.message
                    });
                } else {
                    setMessage({
                        color: MessageColor.error,
                        content: "error to send data to server"
                    })
                }
            } else if (error instanceof Error) {
                setMessage({
                    color: MessageColor.error,
                    content: "error to rendre image"
                });
            }
        }

    };
    const scrollUp = () => {
        const imageContainer = imageContainerRef.current;
        if (!imageContainer) return;
        if (window.innerWidth < 768) imageContainer.scroll({ left: imageContainer.scrollLeft - 200 });
        else imageContainer.scroll({ top: imageContainer.scrollTop - 200 });
    };
    const scrollDown = () => {
        const imageContainer = imageContainerRef.current;
        if (!imageContainer) return;
        if (window.innerWidth < 768) imageContainer.scroll({ left: imageContainer.scrollLeft + 200 });
        else imageContainer.scroll({ top: imageContainer.scrollTop + 200 });
    }
    const uploadImage = async (file: File) => {
        if (file.type !== "image/svg+xml") {
            toast.error("image format invalid");
            return;
        }
        const svgAsText = (await file.text()).replace(/fill="#(\w+)"/g, 'fill="transparent"');
        const blob = new Blob([svgAsText], { type: file.type });

        setLayoutImage({
            blob,
            svgText: svgAsText,
        })

    }
    useEffect(() => {
        window.addEventListener('resize', resize);

        window.addEventListener('mousemove', mousemove);
        window.addEventListener('touchmove', mousemove);

        window.addEventListener('mouseup', mouseup);
        window.addEventListener('touchend', mouseup);
        resize();
        getImage();
        return () => {
            window.removeEventListener('resize', resize);

            window.removeEventListener('mousemove', mousemove);
            window.removeEventListener('touchmove', mousemove);

            window.removeEventListener('mouseup', mouseup);
            window.removeEventListener('touchend', mouseup);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 })
    }, []);
    return <div
        className="h-screen w-screen flex justify-center items-center flex-col md:flex-row select-none relative overflow-hidden"
    >
        <style>
            {`
            *{
                scroll-behavior: smooth;
                -webkit-overflow-scrolling: touch;
                touch-action: none;
            }
            `}
        </style>
        {message ? <Message content={message.content} setMessage={setMessage} color={message.color} /> : null}
        {finish ? <FinishCompose image_url={finish.image_url} setFinish={setFinish} paint={{ id: paintId, config: configPaint, name: data.gallery.paint.name }} /> : null}

        {drapElement}
        {selectImage.current && selectImage.current.element ?
            <div
                className="h-screen w-screen pointer-events-none absolute top-0 left-0 overflow-hidden "
                style={{
                    // touchAction: "none",
                    // WebkitOverflowScrolling: "touch",
                    // scrollBehavior: "smooth",
                }}
            >
                <div
                    className="w-8 h-8 bg-black absolute rounded-md p-1 pointer-events-auto cursor-pointer"
                    style={{
                        top: selectImage.current.element.getBoundingClientRect().y + selectImage.current.element.getBoundingClientRect().height,
                        left: selectImage.current.element.getBoundingClientRect().x + selectImage.current.element.getBoundingClientRect().width
                    }}
                    onMouseDown={(event) => {
                        reScale.current = {
                            mousedown: true,
                            postion: {
                                x: event.clientX,
                                y: event.clientY
                            }
                        }
                    }}
                    onTouchStart={(event) => {
                        reScale.current = {
                            mousedown: true,
                            postion: {
                                x: event.targetTouches[0].clientX,
                                y: event.targetTouches[0].clientY
                            }
                        }
                    }}

                >
                    <img src=".\img\icons\icon-resize.svg" alt="" draggable="false" />
                </div>
            </div>
            :
            null}
        <div className="w-full h-1/3 flex justify-center items-center">
            <div className="w-10/12 md:w-32 min-h-full bg-black rounded-lg flex justify-center items-center flex-col">
                <div className="w-full text-white text-center p-5">
                    <h3>
                        tools
                    </h3>
                </div>
                <div className="w-full text-white flex justify-around items-center h-auto gap-5 md:flex-col md:h-full p-2">
                    <div
                        className=" bg-white p-2 rounded-md cursor-pointer w-12 h-12 flex items-center justify-center"
                        onClick={removeImage}
                        title="remove image"
                    >
                        <img src=".\img\icons\trash-icon.svg" alt="" draggable="false" />
                    </div>
                    <div
                        className="bg-white p-2 rounded-md cursor-pointer w-12 h-12 flex items-center justify-center"
                        title="reload"
                        onClick={reloadImage}
                    >
                        <img src=".\img\icons\refresh-icon.png" alt="" />
                    </div>
                    <div
                        className="w-12 h-12 bg-white p-2  rounded-md"
                        title="post image"
                        onClick={() => postImage()}
                    >
                        <img src=".\img\icons\icon-finish.svg" alt="" draggable="false" />
                    </div>
                    <label
                        className="w-12 h-12 bg-white p-2 rounded-md"
                        title="upload image"
                        htmlFor="image-file"
                    >
                        <img src=".\img\icons\icon-upload.svg" alt="" draggable="false" />
                        <input
                            className="hidden"
                            id="image-file"
                            accept="image/svg+xml"
                            onChange={(event) => {
                                const files = event.target.files;
                                if (!files) return;
                                const file = files[0];
                                uploadImage(file)
                            }}
                            type="file"
                        />
                    </label>
                </div>
            </div>
        </div>
        <div className="w-full h-1/3 flex items-center justify-center">
            <svg

                style={{
                    border: "solid 1px #000"
                }}
                ref={svgRef}
                height={`${size.height}px`}
                width={`${size.width}px`}
                viewBox={`0 0 ${configPaint.interatif.default_size.width} ${configPaint.interatif.default_size.height}`}
            >


                {composeImages.map((composeImage, index) =>
                    <image
                        key={index}
                        onMouseDown={(event) => {
                            if (event.target instanceof Element) {
                                const targetCoord = event.target.getBoundingClientRect();
                                const clientX = event.clientX;
                                const clientY = event.clientY;
                                const offsetX = clientX - targetCoord.x;
                                const offsetY = clientY - targetCoord.y;
                                selectImage.current = { index, firstTouch: { x: offsetX, y: offsetY }, mousedown: true, element: null, size: { ...configCompose.compose.image }, };
                            }
                        }}
                        ref={(svgImage) => {
                            if (svgImage && selectImage.current && index === selectImage.current.index) {
                                selectImage.current.element = svgImage;
                                if (!selectImage.current.size) {
                                    const svgImageRect = svgImage.getBoundingClientRect();
                                    selectImage.current.size = {
                                        width: svgImageRect.width,
                                        height: svgImageRect.height,
                                    };

                                }
                            }
                        }}
                        onTouchStart={(event) => {
                            if (event.target instanceof Element) {
                                const targetCoord = event.target.getBoundingClientRect();
                                const clientX = event.targetTouches[0].clientX;
                                const clientY = event.targetTouches[0].clientY;
                                const offsetX = clientX - targetCoord.x;
                                const offsetY = clientY - targetCoord.y;


                                selectImage.current = { index, firstTouch: { x: offsetX, y: offsetY }, mousedown: true, element: null, size: { ...configCompose.compose.image }, };
                            }
                        }}
                        style={{ outline: selectImage.current && selectImage.current.index === index ? " 2px solid rgba(0, 0, 255, 0.7)" : "" }}
                        href={composeImage.src}
                        width={composeImage.size.width}
                        height={composeImage.size.height}
                        x={composeImage.position.x}
                        y={composeImage.position.y}
                    />
                )}
                {layoutImage && <g
                    x1={0}
                    y1={0}
                    className="pointer-events-none"
                    dangerouslySetInnerHTML={{
                        __html: layoutImage.svgText
                            .replace(/width="(\w.+)"/, `width="${size.width}px"`)
                            .replace(/height="(\w.+)"/, `height="${size.height}px"`)

                    }}></g>}

            </svg>
        </div>
        <div className="w-full h-1/2 flex items-center justify-center">
            <div className="w-10/12 md:w-56 md:h-full bg-black rounded-lg flex justify-center items-center flex-col">
                <div className="w-full text-white h-1/3 md:h-1/6 text-center p-2">
                    <h3>
                        images
                    </h3>
                </div>
                <div className="flex justify-center items-center flex-row overflow-hidden md:flex-col">
                    <div className="text-white p-2 text-3xl rotate-180 md:-rotate-90 cursor-pointer" onClick={scrollUp}>
                        <img src="./img/icons/arrow.svg" alt="" />
                    </div>
                    <div
                        className="w-full text-white flex justify-start items-center h-2/3 gap-2 md:flex-col md:h-full  p-2 overflow-x-scroll overflow-y-hidden md:overflow-x-hidden md:overflow-y-scroll scrollbar-hide"
                        style={{
                            msScrollbarTrackColor: "initial",
                            WebkitOverflowScrolling: "initial",
                            touchAction: "initial"
                        }}
                        ref={imageContainerRef}
                    >
                        {images.map((image, index) => {
                            return <div className="min-w-[20%] md:min-w-[30%]  flex justify-center items-center" key={index}>
                                <img
                                    src={image} alt="" className="w-full" draggable="false"
                                    ref={(img) => img && setListener(img)}
                                />
                            </div>
                        })}
                    </div>
                    <div className="text-white p-2 text-3xl h-auto md:rotate-90 cursor-pointer" onClick={scrollDown}>
                        <img src="./img/icons/arrow.svg" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default Compose;
export const loader: LoaderFunction = async ({ params, request }) => {
    const paintId = params.id;
    const isLog = await isLogin();
    if (!isLog) return redirect(`/login?paintId=${params.id}`);
    const { user } = isLog;
    if (user) {
        const paint = user.paints.find((paint) => paint._id === params.id);
        if (paint) {
            const responce = await axios.post(`${config.api_server_uri}/paint/gallery/${paintId}`);

            const gallery = responce.data;

            return {
                gallery,
                user,
            };
        } else {
            return redirect("/paint");
        }
    }
}