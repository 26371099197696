import configGlobal from "../config";
import MessageColor from "../config/colors";
import { io, Socket } from "socket.io-client";
import getPosition from "../utils/get-position";
import Drawn from "./drawn";
import drawEvent from "../@types/draw-event";
import DrawUser from "../@types/draw-user";
import TMouseEvent from "../@types/mouse-event";

const matrix = new DOMMatrix();

class Pen {
  color: string;
  canvas: HTMLCanvasElement | null;
  ctx: CanvasRenderingContext2D | null;
  option: {
    canvas: {
      size: Size;
    };
    sizeRasio: number;
  };
  mouse: {
    lastPosition: Position;
    down: boolean;
  };
  brush: Brush;
  status: boolean;
  svg: SVGSVGElement | null;
  eventSetup: boolean;

  storages: CanvasStorage[];
  drawn: Drawn;
  users: DrawUser[];
  use_image: boolean;
  canPost = false;
  socket?: Socket;
  isChef = false;
  config: Config;
  constructor(color: string, size: number, use_image: boolean, config: Config) {
    this.storages = [];
    this.users = [];
    this.use_image = use_image;
    this.drawn = new Drawn();
    this.color = color;
    this.canvas = null;
    this.ctx = null;
    this.status = true;
    this.svg = null;
    this.config = config;
    this.option = {
      canvas: {
        size: {
          width: this.config.canvas.default_size.width,
          height: this.config.canvas.default_size.height,
        },
      },
      sizeRasio:
        this.config.canvas.default_size.width /
        this.config.canvas.default_size.height,
    };
    this.mouse = {
      lastPosition: {
        x: 0,
        y: 0,
      },
      down: false,
    };
    this.eventSetup = false;
    this.brush = {
      size: 8,
      type: "marker",
      cursor_style: "cursor-brush03",
      iconPath: "/public/img/icons/cursor-pencil-03-1x.png",
    };
    this.draw_canvas();
  }
  setCanvas(canvas: HTMLCanvasElement) {
    console.log("canvas as been set");

    this.canvas = canvas;
    this.ctx = this.canvas.getContext("2d");
    this.resize();
    this.updateBrush();
  }
  updateUser() {
    const { setUsers, canvas, users } = this;
    if (!setUsers || !canvas) return;
    const canvasInfo = canvas.getBoundingClientRect();
    const newPosition = users.map((user) => {
      const x = (user.mouse.x * canvas.width) / user.mouse.viewBox.width;
      const y = (user.mouse.y * canvas.height) / user.mouse.viewBox.height;
      return {
        x: canvasInfo.x + x,
        y: canvasInfo.y + y,
        username: user.username,
        color: user.color,
        iconPath: user.mouse.iconPath,
      };
    });

    setUsers(newPosition);
  }
  setSocket(token: string, paintId: string) {
    console.log(token);
    const title = document.title;
    this.socket = io(configGlobal.socket_server_uri, {
      query: { token, paintId, liveDraw: true },
    });
    const { socket } = this;

    socket.on("connect", () => {
      if (!this.setLiveDrawIsConnnect) return;
      if (!this.setMessage) return;

      this.setLiveDrawIsConnnect(true);
      this.setMessage({
        color: MessageColor.success,
        content: "server conncet",
      });
    });
    socket.on("message", ({ message, type, color, socketId }) => {
      if (!this.setMessage) return;
      if (type === "token") {
        localStorage.removeItem(paintId);
        window.location.reload();
      } else if (type === "message") {
        this.setMessage({ color: MessageColor.success, content: message });
      } else {
        this.setMessage({ color: MessageColor.error, content: message });
      }
    });

    socket.on(
      "new-user",
      (user: { socketId: string; color: string; username: string }) => {
        if (socket.id === user.socketId) return;

        this.users.push({
          color: user.color,
          username: user.username,
          socketId: user.socketId,
          drawn: new Drawn(),
          size: 0,
          viewBox: {
            height: 0,
            width: 0,
          },
          mouse: {
            x: 0,
            y: 0,
            viewBox: {
              height: 0,
              width: 0,
            },
            iconPath:
              "https://img.icons8.com/external-those-icons-flat-those-icons/43/external-Mouse-Pointer-selection-and-cursors-those-icons-flat-those-icons-10.png",
          },
        });
        this.updateUser();

        if (!this.setMessage) return;
        this.setMessage({
          color: user.color,
          content: `${user.username} join new room`,
        });
      }
    );
    socket.on("undo", (socketId) => {
      if (socket.id === socketId) return;
      this.storages.pop();
    });
    socket.on("clear", (socketId) => {
      if (socket.id === socketId) return;
      this.storages = [];
    });
    socket.on("disconnect", () => {
      this.isChef = false;
      document.title = `${title}`;
      if (!this.setMessage) return;
      this.setMessage({
        color: MessageColor.error,
        content: "server disconnect",
      });
      this.users = [];
      this.storages = [];
    });

    socket.on("draw", (event: drawEvent, socketId: string) => {
      const { socket } = this;
      this.canPost = true;
      if (!socket) return;
      if (socket.id === socketId) return;
      const user = this.users.find((user) => user.socketId === socketId);

      if (user) {
        if (event.draw.position) {
          user.color = event.draw.color;
          user.size = event.draw.size;
          user.viewBox = event.draw.viewBox;

          if (event.type === "begin") {
            user.drawn.reset();
            user.drawn.mouseTo(event.draw.position.x, event.draw.position.y);
          } else if (event.type === "move") {
            user.drawn.lineTo(event.draw.position.x, event.draw.position.y);
          } else {
            this.storages.push({
              color: event.draw.color,
              d: user.drawn.dPath,
              size: event.draw.size,
              type: "stroke",
              viewBox: event.draw.viewBox,
            });
            user.drawn.reset();
          }
        } else {
          if (event.draw.d) {
            this.storages.push({
              color: event.draw.color,
              d: event.draw.d,
              size: event.draw.size,
              type: "fill",
              viewBox: event.draw.viewBox,
            });
          } else {
            this.storages.push({
              color: event.draw.color,
              d: user.drawn.dPath,
              size: event.draw.size,
              type: "stroke",
              viewBox: event.draw.viewBox,
            });
          }
        }
      }
    });
    socket.on("user-disconnect", ({ socketId, username }) => {
      const userIndex = this.users.findIndex(
        (usr) => usr.socketId === socketId
      );
      this.users.splice(userIndex, 1);
      if (!this.setMessage) return;
      this.setMessage({
        color: MessageColor.error,
        content: `${username} disconnect`,
      });
    });
    socket.on("set-storages", (storages: CanvasStorage[]) => {
      this.storages = storages;
    });
    socket.on("mousemove", (socketId: string, mouseevnt: TMouseEvent) => {
      if (socketId === socket.id) return;
      const user = this.users.find((usr) => usr.socketId === socketId);
      if (!user) return;
      user.mouse = mouseevnt;
      this.updateUser();
    });
    socket.on("chef", () => {
      if (this.isChef) return;
      this.isChef = true;
      document.title = `${title} 👑`;
      socket.on("chef-remove", () => {
        this.isChef = false;
        document.title = `${title}`;
        if (!this.setMessage) return;
        this.setMessage({
          color: MessageColor.error,
          content: "you are not the manage the meeting any more",
        });
      });
      socket.on("get-storages", (socketID) => {
        socket.emit("storages", this.storages, socketID);
      });
      if (!this.setMessage) return;
      this.setMessage({
        color: MessageColor.success,
        content: "you manage the meeting",
      });
    });
  }
  setSVG(svg: SVGSVGElement) {
    this.svg = svg;
    this.setup();
    svg.setAttribute("width", `${this.option.canvas.size.width}px`);
    svg.setAttribute("height", `${this.option.canvas.size.height}px`);
    const AllPaths = svg.querySelectorAll("path");
    AllPaths.forEach((path) => {
      if (path.getAttribute("fill") === "#FFFFFF") {
        path.setAttribute("fill", "transparent");
        path.setAttribute("fillable", "true");
      }
    });
    this.updateBrush();
  }
  render(): string | undefined {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = this.config.canvas.render.size.width;
    canvas.height = this.config.canvas.render.size.height;
    if (ctx) {
      ctx.beginPath();
      ctx.lineJoin = "round";
      ctx.lineCap = "round";
      // ctx.fillStyle = "#FFF";
      // ctx.fillRect(0, 0, canvas.width, canvas.height);
      for (let i = 0; i < this.storages.length; i++) {
        ctx.save();
        const currentStorage = this.storages[i];
        const path2D = new Path2D(currentStorage.d);
        ctx.setTransform(
          matrix.scale(
            ctx.canvas.width / currentStorage.viewBox.width,
            ctx.canvas.height / currentStorage.viewBox.height
          )
        );

        ctx.lineWidth = this.lineWidth(
          currentStorage.size,
          currentStorage.viewBox
        );
        if (currentStorage.type === "stroke") {
          ctx.strokeStyle = currentStorage.color;
          ctx.stroke(path2D);
        } else {
          ctx.fillStyle = currentStorage.color;
          ctx.fill(path2D);
        }
        ctx.restore();
      }
      if (this.svg) {
        const allSvgPath = this.svg.querySelectorAll("path");
        for (let i = 0; i < allSvgPath.length; i++) {
          ctx.save();
          ctx.setTransform(
            matrix.scale(
              ctx.canvas.width / this.svg.viewBox.animVal.width,
              ctx.canvas.height / this.svg.viewBox.animVal.height
            )
          );
          const svgPath = allSvgPath[i];
          const pathD = svgPath.getAttribute("d");
          const color = svgPath.getAttribute("fillable")
            ? "transparent"
            : "#000";

          if (pathD) {
            const path2D = new Path2D(pathD);
            ctx.fillStyle = color;
            ctx.fill(path2D);
          }
          ctx.restore();
        }
      }
      ctx.closePath();

      //--** socket user in */
      return canvas.toDataURL("image/png");
    } else {
      if (!this.setMessage) return;
      this.setMessage({
        color: MessageColor.error,
        content: "cont get context from canavs",
      });
      return;
    }
  }
  svgClick(event: MouseEvent) {
    if (!this.status || !this.svg || !this.canvas) return;
    this.canPost = true;
    const pathEl = event.target;

    if (!(pathEl instanceof SVGPathElement)) return;
    if (!pathEl.getAttribute("fillable")) return;

    const pathD = pathEl.getAttribute("d");
    if (!pathD) return;
    this.storages.push({
      color: this.color,
      size: this.brush.size,
      d: pathD,
      type: "fill",
      viewBox: {
        width: this.svg.viewBox.animVal.width,
        height: this.svg.viewBox.animVal.height,
      },
    });
    if (this.socket) {
      const event: drawEvent = {
        draw: {
          color:
            this.brush.type === "eraser"
              ? this.config.eraser.color
              : this.color,
          size:
            this.brush.type === "eraser"
              ? this.config.eraser.size
              : this.brush.size,
          d: pathD,
          viewBox: {
            width: this.svg.viewBox.animVal.width,
            height: this.svg.viewBox.animVal.height,
          },
        },
        type: "close",
      };
      this.socket.emit("draw", event);
    }
  }
  undo() {
    this.storages.pop();
    if (this.socket) this.socket.emit("undo");
  }
  resize() {
    if (!this.canvas) return;

    if (window.innerWidth <= this.config.canvas.default_size.width) {
      const newWidth = window.innerWidth * 0.9;
      const Newscale = newWidth / this.config.canvas.default_size.width;
      const newHieght = this.config.canvas.default_size.height * Newscale;

      this.option.canvas.size = {
        width: newWidth,
        height: newHieght,
      };
    } else {
      this.option.canvas.size = {
        width: this.config.canvas.default_size.width,
        height: this.config.canvas.default_size.height,
      };
    }

    this.canvas.width = this.option.canvas.size.width;
    this.canvas.height = this.option.canvas.size.height;

    if (!this.svg) return;
    this.svg.setAttribute("width", `${this.option.canvas.size.width}px`);
    this.svg.setAttribute("height", `${this.option.canvas.size.height}px`);

    if (this.svg.parentElement) {
      this.svg.parentElement.style.width = `${this.option.canvas.size.width}px`;
      this.svg.parentElement.style.height = `${this.option.canvas.size.height}px`;
    }
    // if (this.config.canvas.default_size.width >= window.innerWidth) {
    //     const p10 = window.innerWidth * 0.20;
    //     if (window.innerWidth > this.config.canvas.minsize) {
    //         this.option.canvas.size = {
    //             width: window.innerWidth - p10,
    //             height: (window.innerWidth * this.option.sizeRasio) - p10
    //         }
    //     } else {
    //         this.option.canvas.size = {
    //             width: this.config.canvas.minsize - p10,
    //             height: this.config.canvas.minsize * this.option.sizeRasio - p10
    //         }
    //     }
    // } else {
    //     this.option.canvas.size = {
    //         width: this.config.canvas.default_size.width,
    //         height: this.config.canvas.default_size.height
    //     }
    // }
    // this.canvas.width = this.option.canvas.size.width;
    // this.canvas.height = this.option.canvas.size.height;
    // if (!this.svg) return;
    // this.svg.setAttribute('width', `${this.option.canvas.size.width}px`);
    // this.svg.setAttribute('height', `${this.option.canvas.size.height}px`);
    // if (this.svg.parentElement) {
    //     this.svg.parentElement.style.width = `${this.option.canvas.size.width}px`
    //     this.svg.parentElement.style.height = `${this.option.canvas.size.height}px`
    // }
  }
  setColor(color: string) {
    this.color = color;
  }
  setBrush(brush: Brush) {
    if (this.canvas) {
      this.brush = brush;
      this.updateBrush();
    } else {
      if (this.setMessage)
        this.setMessage({
          color: MessageColor.error,
          content: "error to find canvas",
        });
      else alert("error to show error");
    }
  }
  updateBrush() {
    if (this.use_image && !this.svg) return;
    if (!this.canvas) return;
    document.body.style.cursor = `url("${this.brush.iconPath}"), pointer`;

    if (this.svg) {
      if (this.brush.type === "bucket")
        this.svg.parentElement?.classList.add("active");
      else this.svg.parentElement?.classList.remove("active");
    }
  }
  setup() {
    this.event();
  }
  lineWidth(penSize: number, size: Size): number {
    if (!this.canvas) return -1;
    return (penSize * size.width) / this.config.canvas.default_size.width;
  }
  draw_canvas() {
    if (!this.status) return;
    if (this.canvas && this.ctx) {
      this.resize();
      const ctx = this.ctx;
      ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

      ctx.beginPath();
      ctx.lineJoin = "round";
      ctx.lineCap = "round";
      for (let i = 0; i < this.storages.length; i++) {
        ctx.save();
        const currentStorage = this.storages[i];
        const path2D = new Path2D(currentStorage.d);

        ctx.setTransform(
          matrix.scale(
            ctx.canvas.width / currentStorage.viewBox.width,
            ctx.canvas.height / currentStorage.viewBox.height
          )
        );
        ctx.lineWidth = this.lineWidth(
          currentStorage.size,
          currentStorage.viewBox
        );
        if (currentStorage.type === "stroke") {
          ctx.strokeStyle = currentStorage.color;
          ctx.stroke(path2D);
        } else {
          ctx.fillStyle = currentStorage.color;
          ctx.fill(path2D);
        }
        ctx.restore();
      }
      for (let i = 0; i < this.users.length; i++) {
        const user = this.users[i];
        ctx.save();
        const path2D = user.drawn.path2d;

        ctx.setTransform(
          matrix.scale(
            ctx.canvas.width / user.viewBox.width,
            ctx.canvas.height / user.viewBox.height
          )
        );
        ctx.lineWidth = this.lineWidth(user.size, user.viewBox);

        ctx.strokeStyle = user.color;
        ctx.stroke(path2D);
        ctx.restore();
      }
      ctx.strokeStyle =
        this.brush.type === "eraser" ? this.config.eraser.color : this.color;

      ctx.lineWidth = this.lineWidth(
        this.brush.type === "eraser"
          ? this.config.eraser.size
          : this.brush.size,
        this.option.canvas.size
      );
      ctx.stroke(this.drawn.path2d);
      ctx.closePath();
    }
    requestAnimationFrame(this.draw_canvas.bind(this));
  }
  mousemove(event: MouseEvent | TouchEvent) {
    if (!this.canvas) return;
    let position: Position;
    if (event instanceof MouseEvent)
      position = getPosition(
        { x: event.clientX, y: event.clientY },
        this.canvas
      );
    else
      position = getPosition(
        {
          x: event.targetTouches[0].clientX,
          y: event.targetTouches[0].clientY,
        },
        this.canvas
      );

    if (this.socket) {
      const mouseevent: TMouseEvent = {
        iconPath: this.brush.iconPath,
        x: position.x,
        y: position.y,
        viewBox: {
          width: this.canvas.width,
          height: this.canvas.height,
        },
      };
      this.socket.emit("mousemove", mouseevent);
    }

    if (!this.status || !this.mouse.down) return;
    this.canPost = true;

    this.drawn.lineTo(position.x, position.y);
    if (this.socket) {
      const event: drawEvent = {
        draw: {
          color:
            this.brush.type === "eraser"
              ? this.config.eraser.color
              : this.color,
          size:
            this.brush.type === "eraser"
              ? this.config.eraser.size
              : this.brush.size,
          position,
          viewBox: {
            width: this.canvas.width,
            height: this.canvas.height,
          },
        },
        type: "move",
      };
      this.socket.emit("draw", event);
    }
  }
  mouseup() {
    if (!this.canvas || !this.status || !this.mouse.down) return;
    this.mouse.down = false;
    this.storages.push({
      color:
        this.brush.type === "eraser" ? this.config.eraser.color : this.color,
      size:
        this.brush.type === "eraser"
          ? this.config.eraser.size
          : this.brush.size,
      d: this.drawn.dPath,
      type: "stroke",
      viewBox: {
        width: this.canvas.width,
        height: this.canvas.height,
      },
    });

    this.drawn.reset();
    if (this.socket) {
      const event: drawEvent = {
        draw: {
          color:
            this.brush.type === "eraser"
              ? this.config.eraser.color
              : this.color,
          size:
            this.brush.type === "eraser"
              ? this.config.eraser.size
              : this.brush.size,
          viewBox: {
            width: this.canvas.width,
            height: this.canvas.height,
          },
          position: {
            x: 0,
            y: 0,
          },
        },
        type: "close",
      };
      this.socket.emit("draw", event);
    }
  }
  mousedown(event: MouseEvent | TouchEvent) {
    if (!this.canvas || !this.status) return;
    let position: Position;
    if (event instanceof MouseEvent) {
      if (event.button !== 0) return;
      position = getPosition(
        { x: event.clientX, y: event.clientY },
        this.canvas
      );
    } else
      position = getPosition(
        {
          x: event.targetTouches[0].clientX,
          y: event.targetTouches[0].clientY,
        },
        this.canvas
      );

    this.mouse.down = true;
    this.drawn.mouseTo(position.x, position.y);
    if (this.socket) {
      const event: drawEvent = {
        draw: {
          color:
            this.brush.type === "eraser"
              ? this.config.eraser.color
              : this.color,
          size:
            this.brush.type === "eraser"
              ? this.config.eraser.size
              : this.brush.size,
          position,
          viewBox: {
            width: this.canvas.width,
            height: this.canvas.height,
          },
        },
        type: "begin",
      };
      this.socket.emit("draw", event);
    }
  }
  event() {
    if (!this.canvas || !this.status || this.eventSetup) return;
    if (this.use_image && !this.svg) return;
    this.eventSetup = true;

    window.addEventListener("mousemove", this.mousemove.bind(this));
    window.addEventListener("touchmove", this.mousemove.bind(this));

    window.addEventListener("mouseup", this.mouseup.bind(this));
    window.addEventListener("touchend", this.mouseup.bind(this));

    this.canvas.addEventListener("mousedown", this.mousedown.bind(this));
    this.canvas.addEventListener("touchstart", this.mousedown.bind(this));

    this.svg?.addEventListener("click", this.svgClick.bind(this));
  }
  clear() {
    this.storages = [];
    if (this.socket) {
      this.socket.emit("clear");
    }
  }
  destroy() {
    if (this.canvas) this.status = false;
    if (this.socket) {
      this.socket.disconnect();
      this.socket.off();
    }
  }
  setMessage: React.Dispatch<
    React.SetStateAction<{ color: string; content: string } | null>
  > | null = null;
  setLiveDrawIsConnnect: React.Dispatch<React.SetStateAction<boolean>> | null =
    null;
  setUsers?: React.Dispatch<
    React.SetStateAction<
      {
        x: number;
        y: number;
        color: string;
        username: string;
        iconPath: string;
      }[]
    >
  >;
}
export default Pen;
