import { LoaderFunction, redirect, useLoaderData, useNavigate, useParams } from "react-router-dom";
import isLogin from "../../../utils/isLogin";
import NotFound from "../../NotFound";
import { useRef, useState } from "react";
import ConfigElement from "../../../components/config";
import StatusList from "../../../utils/status";
import config from "../../../config";

import axios, { AxiosError } from "axios";
import { NavLink } from "react-router-dom";

const Paint = () => {
    const data = useLoaderData() as { paint: TPaint; user: User; token: string } | null;
    const paintData = data?.paint;
    const [submit, setSubmit] = useState(false);
    const [paint, setPaint] = useState(paintData);

    const [showConfig, setShowConfig] = useState(false);
    const [message, setMessage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loader, setLoader] = useState(false);
    const mainContainer = useRef<HTMLDivElement | null>(null);
    const { paintId } = useParams();

    const navigate = useNavigate();
    const scrollToTop = () => {
        // if (mainContainer.current) {
        //     // mainContainer.current.scrollTo({ top: 0 })
        //     mainContainer.current.scrollTop = 0;
        //     // mainContainer.current.scrollIntoView({ behavior: "smooth", })
        // } else {
        //     setError("error to scroll to top")
        // }
    };
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoader(true);
        if (!data) return;
        const { user } = data;

        if (!paint || !user) {
            scrollToTop();
            return setMessage("error ot find user or paint");
        }
        try {
            const { data }: { data: { code: number; message: string } } = await axios.post(`${config.api_server_uri}/admin/paint/edit/${paintId}`, paint, { headers: { authorization: user.token } });
            setSubmit(false);
            setLoader(false);

            setMessage(data.message);
            scrollToTop();
        } catch (error) {
            if (error instanceof AxiosError) {
                const data = error.response?.data;
                if (data) {
                    setError(data.message);
                } else {
                    setError("error to send data to server");
                }
            } else {
                setError("error to send data to server");
            }
            setLoader(false);
        }
    };
    const removePaint = async () => {
        if (!data) return;
        const { user } = data;
        try {
            const response = await axios.post(`${config.api_server_uri}/admin/paint/delete/${paintId}`, null, { headers: { authorization: user.token } });
            setMessage(response.data.message);
            navigate("/admin");
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.response) {
                    setError(error.response.data.message);
                }
            }
        }
    };
    const clearGallery = async (lableId?: string) => {
        try {
            if (!data) return;
            const { user } = data;
            if (lableId) {
                const response = await axios.post(`${config.api_server_uri}/admin/paint/gallery/clear/${paintId}/${lableId}`, null, { headers: { authorization: user.token } });
                if (response.status === 200) {
                    setMessage(response.data.message);
                } else {
                    setMessage(response.data.message);
                }
                scrollToTop();
            } else {
                const response = await axios.post(`${config.api_server_uri}/admin/paint/gallery/clear/${paintId}/`, null, { headers: { authorization: user.token } });
                if (response.status === 200) {
                    setMessage(response.data.message);
                } else {
                    setMessage(response.data.message);
                }
                scrollToTop();
            }
        } catch (error) {
            if (error instanceof AxiosError) {
                setError(error.response?.data);
                scrollToTop();
            } else if (error instanceof Error) {
                setError(error.message);
                scrollToTop();
            }
        }
    };
    const reloadGalleryInstance = async () => {
        try {
            if (!data) return;
            const { user } = data;
            const response = await axios.post(`${config.api_server_uri}/admin/paint/gallery/reload/${paintId}/`, null, { headers: { authorization: user.token } });
            setMessage(response.data.message);
            scrollToTop();
        } catch (error) {
            if (error instanceof AxiosError) {
                setError(error.response?.data);
                scrollToTop();
            } else if (error instanceof Error) {
                setError(error.message);
                scrollToTop();
            }
        }
    };
    return (
        <div ref={mainContainer}>
            {paint ? (
                <div className="h-screen w-screen dark:bg-gray-800 overflow-x-hidden">
                    <form className="h-auto w-screen dark:bg-gray-800" onSubmit={handleSubmit}>
                        <div className="sticky top-0 left-0 z-40">
                            {message ? (
                                <div className="h-10 w-full flex items-center justify-center dark:text-white pt-16 flex-col">
                                    <div className="w-full sm:w-1/2">
                                        <div
                                            id="alert-border-3"
                                            className="flex p-4 mb-4 text-green-800 border-t-4 border-green-300 bg-green-50 dark:text-green-400 dark:bg-gray-900 dark:border-green-800 rounded-xl"
                                        >
                                            <svg className="flex-shrink-0 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                                    clipRule="evenodd"
                                                ></path>
                                            </svg>
                                            <div className="ml-3 text-sm font-medium">{message}</div>
                                            <button
                                                type="button"
                                                className="ml-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-gray-800 dark:text-green-400 dark:hover:bg-gray-700"
                                                onClick={() => {
                                                    setError(null);
                                                    setMessage(null);
                                                }}
                                            >
                                                <span className="sr-only">Dismiss</span>
                                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                        clipRule="evenodd"
                                                    ></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {error ? (
                                <div className="h-10 w-full flex items-center justify-center dark:text-white pt-16 flex-col">
                                    <div className="w-full sm:w-1/2">
                                        <div
                                            id="alert-border-3"
                                            className="flex p-4 mb-4 text-red-800 border-t-4 border-red-300 bg-red-50 dark:text-red-400 dark:bg-red-900 dark:border-red-800 rounded-xl"
                                        >
                                            <svg className="flex-shrink-0 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                                    clipRule="evenodd"
                                                ></path>
                                            </svg>
                                            <div className="ml-3 text-sm font-medium">{error}</div>
                                            <button
                                                type="button"
                                                className="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex h-8 w-8 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-gray-700"
                                                onClick={() => {
                                                    setError(null);
                                                    setMessage(null);
                                                }}
                                            >
                                                <span className="sr-only">Dismiss</span>
                                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                        clipRule="evenodd"
                                                    ></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        {submit ? (
                            <div className="h-10 fixed top-28 right-8 z-40">
                                {loader ? (
                                    <div role="status" className=" flex w-20 h-20 justify-center items-center">
                                        <svg className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="currentColor"
                                            />
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentFill"
                                            />
                                        </svg>
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                ) : (
                                    <button
                                        type="submit"
                                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                    >
                                        edit paint
                                    </button>
                                )}
                            </div>
                        ) : null}
                        <div className="absolute top-0 right-0 w-36 h-36 flex items-center justify-center z-30">
                            <div className="flex items-center justify-center ">
                                <div className="m-5">
                                    <div className="flex p-2.5 transition-all duration-300 text-white rounded-xl bg-gray-800 hover:bg-gray-700 cursor-pointer" onClick={() => navigate(-1)}>
                                        <svg className="h-10 w-10" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="h-auto w-full flex items-center justify-center dark:text-white pt-16 flex-col">
                            <div className="inline-block align-bottom bg-white dark:bg-gray-800 text-left overflow-hidden shadow transform transition-all sm:w-1/2 rounded-none sm:rounded-lg w-full">
                                <div className="bg-white p-5 dark:bg-gray-900 ">
                                    <div className="sm:flex sm:items-start ">
                                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                            <h3 className="text-sm leading-6 font-medium text-gray-400">name</h3>
                                            <input
                                                className="text-2xl font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg px-1 w-full"
                                                value={paint.name}
                                                onChange={(event) => {
                                                    setPaint({ ...paint, name: event.target.value });
                                                    setSubmit(true);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="inline-block align-bottom bg-white dark:bg-gray-800 text-left overflow-hidden shadow transform transition-all w-full sm:w-1/2 sm:mt-3 rounded-none sm:rounded-lg">
                                <div className="bg-white p-5 dark:bg-gray-900">
                                    <div className="sm:flex sm:items-start">
                                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                            <h3 className="text-sm leading-6 font-medium text-gray-400">statue</h3>
                                            <select
                                                value={paint.statue}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                onChange={(event) => {
                                                    if (StatusList.some((status) => event.target.value === status)) {
                                                        setPaint({
                                                            ...paint,
                                                            statue: event.target.value as any,
                                                        });
                                                        setSubmit(true);
                                                    }
                                                }}
                                            >
                                                {StatusList.map((status, index) => (
                                                    <option key={index} value={status}>
                                                        {status}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="inline-block align-bottom bg-white dark:bg-gray-800 text-left overflow-hidden shadow transform transition-all w-full sm:w-1/2 sm:mt-3 rounded-none sm:rounded-lg">
                                <div className="bg-white p-5 dark:bg-gray-900">
                                    <div className="sm:flex sm:items-start">
                                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                            <h3 className="text-sm leading-6 font-medium text-gray-400">config</h3>
                                            {showConfig ? (
                                                <ConfigElement
                                                    config={paint.config}
                                                    setConfig={(config) => {
                                                        setPaint({ ...paint, config })
                                                        setSubmit(true);
                                                    }}

                                                />
                                            ) : null}
                                            <div className="w-full flex justify-center items-center">
                                                <button
                                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                                    type="button"
                                                    onClick={(event) => {
                                                        setShowConfig(!showConfig);
                                                    }}
                                                >
                                                    {showConfig ? "hide config" : "show config"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="inline-block align-bottom bg-white dark:bg-gray-800 text-left overflow-hidden shadow transform transition-all w-full sm:w-1/2 sm:mt-3 rounded-none sm:rounded-lg">
                                <div className="bg-white p-5 dark:bg-gray-900">
                                    <div className="sm:flex sm:items-start">
                                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                            <h3 className="text-sm leading-6 font-medium text-gray-400"> stripe payment id</h3>
                                            <span className="block w-full">{paint.payment_intent_id}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="inline-block align-bottom bg-white dark:bg-gray-800 text-left overflow-hidden shadow transform transition-all w-full sm:w-1/2 sm:mt-3 rounded-none sm:rounded-lg">
                                <div className="bg-white p-5 dark:bg-gray-900">
                                    <div className="sm:flex sm:items-start">
                                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                            <h3 className="text-sm leading-6 font-medium text-gray-400">gallery url</h3>
                                            <span className="w-full justify-center items-center flex">
                                                <NavLink
                                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                                    to={`/gallery/${paint._id}`}
                                                >
                                                    show gallery
                                                </NavLink>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="inline-block align-bottom bg-white dark:bg-gray-800 text-left overflow-hidden shadow transform transition-all w-full sm:w-1/2 sm:mt-3 rounded-none sm:rounded-lg">
                                <div className="bg-white p-5 dark:bg-gray-900">
                                    <div className="sm:flex sm:items-start">
                                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                            <h3 className="text-sm leading-6 font-medium text-gray-400">compose url</h3>
                                            <span className="w-full justify-center items-center flex">
                                                <NavLink
                                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                                    to={`/paint/${paint._id}/compose`}
                                                >
                                                    show compose
                                                </NavLink>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="inline-block align-bottom bg-white dark:bg-gray-800 text-left overflow-hidden shadow transform transition-all w-full sm:w-1/2 sm:mt-3 rounded-none sm:rounded-lg">
                                <div className="bg-white p-5 dark:bg-gray-900">
                                    <div className="sm:flex sm:items-start">
                                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                            <h3 className="text-sm leading-6 font-medium text-gray-400">review url</h3>
                                            <span className="w-full justify-center items-center flex">
                                                <NavLink
                                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                                    to={`/review/${paint._id}`}
                                                >
                                                    go to review page
                                                </NavLink>
                                                <button
                                                    type="button"
                                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                                    onClick={async () => {
                                                        const reviewUrl = `${window.location.origin}/review/${paint._id}`;
                                                        try {
                                                            await navigator.clipboard.writeText(reviewUrl);
                                                            setMessage("reviewUrl copyed successfully");
                                                            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                                                        } catch (err) {
                                                            setError("error to copy reviewUrl in clipboard");
                                                            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                                                        }
                                                    }}
                                                >
                                                    copy review url
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="inline-block align-bottom bg-white dark:bg-gray-800 text-left overflow-hidden shadow transform transition-all w-full sm:w-1/2 sm:mt-3 rounded-none sm:rounded-lg">
                                <div className="bg-white p-5 dark:bg-gray-900">
                                    <div className="sm:flex sm:items-start">
                                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                            <h3 className="text-sm leading-6 font-medium text-gray-400">create by</h3>
                                            <div className="ml-5">
                                                <span className="block w-full">{paint.user.email}</span>
                                                <span className="block w-full">{`${paint.user.firstName} ${paint.user.lastName}`}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="inline-block align-bottom bg-white dark:bg-gray-800 text-left overflow-hidden shadow transform transition-all w-full sm:w-1/2 sm:mt-3 rounded-none sm:rounded-lg">
                                <div className="bg-white p-5 dark:bg-gray-900">
                                    <div className="sm:flex sm:items-start">
                                        <div className="text-center sm:mt-0 sm:ml-2 sm:text-left w-full">
                                            <h3 className="text-sm leading-6 font-medium text-gray-400">notification</h3>
                                            <div className="ml-1 px-1">
                                                <div className="text-sm font-medium text-gray-400 mb-1 mt-2">review</div>
                                                <div className="flex sm:justify-start justify-center items-start ml-4 flex-col">
                                                    <div className="text-sm font-medium text-gray-400 mb-1">use</div>
                                                    <input
                                                        type="checkbox"
                                                        disabled={!paint.config.review.use}
                                                        value=""
                                                        checked={!!paint.config.review.notification.use}
                                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mb-2"
                                                        onChange={(event) => {
                                                            setPaint((paint) => {
                                                                if (!paint) return undefined;
                                                                paint.config.review.notification.use = event.target.checked;
                                                                setSubmit(true);
                                                                return { ...paint };
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="flex sm:justify-start justify-center items-start ml-4 flex-col">
                                                    <div className="text-sm font-medium text-gray-400 mb-3">email</div>
                                                    <input
                                                        type="text"
                                                        disabled={!paint.config.review.use}
                                                        value={paint.config.review.notification.email}
                                                        className="font-bold text-black dark:text-white dark:bg-gray-800 rounded-lg w-full h-10  px-2"
                                                        onChange={(event) => {
                                                            setPaint((paint) => {
                                                                if (!paint) return undefined;
                                                                paint.config.review.notification.email = event.target.value;
                                                                setSubmit(true);
                                                                return { ...paint };
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="inline-block align-bottom bg-white dark:bg-gray-800 text-left overflow-hidden shadow transform transition-all w-full sm:w-1/2 sm:mt-3 rounded-none sm:rounded-lg">
                                <div className="bg-white dark:bg-gray-900  overflow-x-hidden p-5">
                                    <h3 className="text-sm font-medium text-gray-400 mb-3 sm:ml-2">gallery</h3>
                                    <div className="flex justify-center items-start flex-row gap-5 w-full ml-5">
                                        <label className="text-xs font-medium text-gray-400 mb-3 sm:ml-2 h-full justify-start items-center flex">questions</label>
                                        <div className="w-full h-full justify-start items-center flex">
                                            <input
                                                type="checkbox"
                                                checked={!!paint.config.questions}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        paint.config.questions = [
                                                            {
                                                                label: "new",
                                                                value: "new ?",
                                                            },
                                                        ];
                                                        paint.gallery.storages = [
                                                            {
                                                                images: [],
                                                                label: "new",
                                                                max_images: 50,
                                                            },
                                                        ];
                                                        setPaint({ ...paint });
                                                    } else {
                                                        paint.config.questions = null;
                                                        paint.gallery.storages = [
                                                            {
                                                                images: [],
                                                                label: "defautl",
                                                                max_images: 50,
                                                            },
                                                        ];
                                                        setPaint({ ...paint });
                                                    }
                                                    setSubmit(true);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {paint.config.questions ? (
                                        <div className="flex justify-center items-center flex-col gap-5 w-full">
                                            {paint.gallery.storages.map((storage, index) => {
                                                if (!paint.config.questions) return null;
                                                if (!paint.config.questions[index]) return null;
                                                return (
                                                    <div className="w-full  dark:bg-gray-800 rounded-lg h-auto py-2 " key={index}>
                                                        <div className="w-full h-12 flex justify-center items-center my-5">
                                                            <div className="w-11/12 flex flex-row bg-gray-700 rounded-md h-14">
                                                                <div className="w-1/2 h-full flex justify-center items-center">label</div>
                                                                <div className="w-1/2 flex justify-center items-center p-5">
                                                                    <input
                                                                        type="text"
                                                                        value={storage.label}
                                                                        onChange={(event) => {
                                                                            if (!paint.config.questions) return;
                                                                            const storages = [...paint.gallery.storages];
                                                                            const questions = [...paint.config.questions];
                                                                            storages[index].label = event.target.value;
                                                                            questions[index].label = event.target.value;

                                                                            setPaint({
                                                                                ...paint,
                                                                                config: { ...paint.config, questions },
                                                                                gallery: {
                                                                                    ...paint.gallery,
                                                                                    storages,
                                                                                },
                                                                            });
                                                                            setSubmit(true);
                                                                        }}
                                                                        className="text-2xl font-bold text-black dark:text-white dark:bg-gray-900 rounded-lg px-1 w-full appearance-none"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="w-full h-12 flex justify-center items-center  my-5">
                                                            <div className="w-11/12 flex flex-row bg-gray-700 rounded-md h-14">
                                                                <div className="w-1/2 h-full flex justify-center items-center">count</div>
                                                                <div className="w-1/2 flex justify-center items-center p-5">
                                                                    <input
                                                                        type="number"
                                                                        value={storage.max_images}
                                                                        onChange={(event) => {
                                                                            const storages = [...paint.gallery.storages];
                                                                            storages[index].max_images = event.target.valueAsNumber;

                                                                            setPaint({
                                                                                ...paint,
                                                                                gallery: {
                                                                                    ...paint.gallery,
                                                                                    storages,
                                                                                },
                                                                            });
                                                                            setSubmit(true);
                                                                        }}
                                                                        className="text-2xl font-bold text-black dark:text-white dark:bg-gray-900 rounded-lg px-1 w-full appearance-none"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="w-full h-12 flex justify-center items-center  my-5">
                                                            <div className="w-11/12 flex flex-row bg-gray-700 rounded-md h-14">
                                                                <div className="w-1/2 h-full flex justify-center items-center">question</div>
                                                                <div className="w-1/2 flex justify-center items-center p-5">
                                                                    <input
                                                                        value={paint.config.questions[index].value}
                                                                        type="text"
                                                                        onChange={(event) => {
                                                                            if (!paint.config.questions) return;
                                                                            const questions = [...paint.config.questions];
                                                                            questions[index].value = event.target.value;
                                                                            setPaint({
                                                                                ...paint,
                                                                                config: { ...paint.config, questions },
                                                                            });
                                                                            setSubmit(true);
                                                                        }}
                                                                        className="text-2xl font-bold text-black dark:text-white dark:bg-gray-900 rounded-lg px-1 w-full appearance-none"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="w-full flex justify-end px-5 h-10">
                                                            <div className="w-full h-full flex justify-start">
                                                                <button
                                                                    className="w-56 h-10 flex justify-center items-center bg-blue-600 cursor-pointer rounded-lg hover:bg-blue-700"
                                                                    type="button"
                                                                    onClick={() => clearGallery(storage._id)}
                                                                >
                                                                    clear label gallery
                                                                </button>
                                                            </div>
                                                            <div
                                                                className="w-14 h-10 flex justify-center items-center bg-red-600 cursor-pointer rounded-lg ml-3 hover:bg-red-700"
                                                                onClick={() => {
                                                                    if (!paint.config.questions) return;
                                                                    const storages = [...paint.gallery.storages];
                                                                    const questions = [...paint.config.questions];
                                                                    storages.splice(index, 1);
                                                                    questions.splice(index, 1);

                                                                    setPaint({
                                                                        ...paint,
                                                                        config: { ...paint.config, questions },
                                                                        gallery: {
                                                                            ...paint.gallery,
                                                                            storages,
                                                                        },
                                                                    });
                                                                    setSubmit(true);
                                                                }}
                                                            >
                                                                <div className="w-full flex justify-center items-center">
                                                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                                        ></path>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            <div className="w-full flex justify-end">
                                                <div
                                                    className="bg-blue-700 w-10 h-10 rounded-lg cursor-pointer"
                                                    onClick={() => {
                                                        if (!paint.config.questions) return;

                                                        const storages = [...paint.gallery.storages];
                                                        const questions = [...paint.config.questions];
                                                        storages.push({
                                                            label: "new",
                                                            images: [],
                                                            max_images: 50,
                                                        });
                                                        questions.push({
                                                            label: "new",
                                                            value: "new ?",
                                                        });
                                                        setPaint({
                                                            ...paint,
                                                            config: { ...paint.config, questions },
                                                            gallery: {
                                                                ...paint.gallery,
                                                                storages,
                                                            },
                                                        });
                                                        setSubmit(true);
                                                    }}
                                                >
                                                    <svg
                                                        className="w-10 h-10"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth="1.5"
                                                        viewBox="0 0 24 24"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        aria-hidden="true"
                                                    >
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="flex justify-center items-center flex-col gap-5 w-full">
                                            {paint.gallery.storages.map((storage, index) => {
                                                return (
                                                    <div className="w-full  dark:bg-gray-800 rounded-lg h-auto py-2 " key={index}>
                                                        <div className="w-full h-24 flex justify-center items-center mb-2 ">
                                                            <div className="w-11/12 flex flex-row bg-gray-700 rounded-md h-14">
                                                                <div className="w-1/2 h-full flex justify-center items-center">label</div>
                                                                <div className="w-1/2 flex justify-center items-center p-5">
                                                                    <input
                                                                        disabled
                                                                        type="text"
                                                                        value={storage.label}
                                                                        onChange={(event) => {
                                                                            return;
                                                                        }}
                                                                        className="opacity-80 text-2xl font-bold text-black dark:text-white dark:bg-gray-900 rounded-lg px-1 w-full appearance-none"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="w-full h-12 flex justify-center items-center mb-5 ">
                                                            <div className="w-11/12 flex flex-row bg-gray-700 rounded-md h-14">
                                                                <div className="w-1/2 h-full flex justify-center items-center">count</div>
                                                                <div className="w-1/2 flex justify-center items-center p-5">
                                                                    <input
                                                                        type="number"
                                                                        value={storage.max_images}
                                                                        onChange={(event) => {
                                                                            const storages = [...paint.gallery.storages];
                                                                            storages[index].max_images = event.target.valueAsNumber;
                                                                            setPaint({
                                                                                ...paint,
                                                                                gallery: {
                                                                                    ...paint.gallery,
                                                                                    storages,
                                                                                },
                                                                            });
                                                                            setSubmit(true);
                                                                        }}
                                                                        className=" text-2xl font-bold text-black dark:text-white dark:bg-gray-900 rounded-lg px-1 w-full appearance-none"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="w-full flex justify-end px-5 h-10">
                                                            <div className="w-full h-full flex justify-start">
                                                                <button
                                                                    className="w-56 h-10 flex justify-center items-center bg-blue-600 cursor-pointer rounded-lg hover:bg-blue-700"
                                                                    type="button"
                                                                    onClick={() => clearGallery(storage._id)}
                                                                >
                                                                    clear label gallery
                                                                </button>
                                                            </div>
                                                            <div
                                                                className="w-14 h-10 flex justify-center items-center bg-red-600 cursor-pointer rounded-lg ml-3 hover:bg-red-700"
                                                                onClick={() => {
                                                                    if (!paint.config.questions) return;
                                                                    const storages = [...paint.gallery.storages];
                                                                    const questions = [...paint.config.questions];
                                                                    storages.splice(index, 1);
                                                                    questions.splice(index, 1);

                                                                    setPaint({
                                                                        ...paint,
                                                                        config: { ...paint.config, questions },
                                                                        gallery: {
                                                                            ...paint.gallery,
                                                                            storages,
                                                                        },
                                                                    });
                                                                    setSubmit(true);
                                                                }}
                                                            >
                                                                <div className="w-full flex justify-center items-center">
                                                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                                        ></path>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}

                                    <div className="my-10 w-full flex justify-center items-center">
                                        <a href={`${config.api_server_uri}/paint/gallery/zip/${paint._id}`} target="_blank" rel="noreferrer">
                                            <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center" type="button">
                                                <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                                                </svg>
                                                <span>Download</span>
                                            </button>
                                        </a>
                                    </div>
                                    <div className="my-10 w-full flex justify-center items-center">
                                        <button
                                            className="w-1/2 h-10 flex justify-center items-center bg-green-600 cursor-pointer rounded-lg hover:bg-green-700"
                                            type="button"
                                            onClick={reloadGalleryInstance}
                                        >
                                            reload all gallery instance
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="inline-block align-bottom bg-white dark:bg-gray-800 text-left overflow-hidden shadow transform transition-all w-full sm:w-1/2 sm:mt-3 rounded-none sm:rounded-lg">
                                <div className="bg-white p-5 dark:bg-gray-900">
                                    <div className="sm:flex sm:items-start">
                                        <div className="text-center sm:mt-0 ml-2 sm:text-left w-full">
                                            <div className="w-full flex justify-center">
                                                <button
                                                    className="text-center text-xl font-bold w-auto p-5 h-14 bg-red-600 flex justify-center items-center rounded-xl"
                                                    type="button"
                                                    onClick={removePaint}
                                                >
                                                    remove paint
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="h-10"></div>
                        </div>
                    </form>
                </div>
            ) : (
                <NotFound />
            )}
        </div>
    );
};

export default Paint;

export const loader: LoaderFunction = async ({ params }) => {
    const isLog = await isLogin();
    if (!isLog) return redirect(`/login?PaintAdmin=${params.paintId}`);
    const { user } = isLog;
    if (user) {
        if (user.role === "customer") return redirect("/");
        const paint = user.paints.find((paint) => paint._id === params.paintId);
        if (paint) {
            return {
                paint,
                ...isLog,
            };
        } else {
            return null;
        }
    } else {
        return redirect("/login");
    }
};
